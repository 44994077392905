import omit from 'omit.js'
import React, { CSSProperties, ReactElement, ReactNode, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import classNames from '../../antd/_util/classNames'
import Icon from '../icon'
import { iconToComponent } from '../icon/iconToComponent'

type Props = {
  loading?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
  id?: string
  style?: CSSProperties
  disabled?: boolean
  tabIndex?: number
  type?: 'primary' | 'secondary' | 'tertiary' | 'text'
  danger?: boolean
  size?: 'default' | 'large' | 'extra-large' | 'extra-extra-large'
  htmlType?: 'submit' | 'button' | 'reset'
  className?: string
  prefixIcon?: keyof typeof iconToComponent
  suffixIcon?: keyof typeof iconToComponent
  regular?: boolean
  block?: boolean
  children?: ReactNode
  prefixCls?: string
}

export default function Button(props: Props): ReactElement | null {
  // NEED_NO_TRANSLATION
  const [loading, setLoading] = useState(props.loading || false)

  const previousLoading = usePrevious(props.loading)

  useEffect(() => {
    if (typeof props.loading !== 'boolean') {
      return
    }
    if (previousLoading !== props.loading) {
      setLoading(props.loading)
    }
  }, [previousLoading, props.loading])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const onClick = props.onClick
    if (onClick) {
      onClick(e)
    }
  }

  const {
    type = 'tertiary',
    danger = false,
    size,
    className,
    htmlType,
    children,
    regular,
    block,
    prefixCls = 'ant-btn',
    prefixIcon,
    suffixIcon,
    ...others
  } = props

  let sizeCls = ''
  switch (size) {
    case 'extra-extra-large':
      sizeCls = 'xxl'
      break
    case 'extra-large':
      sizeCls = 'xl'
      break
    case 'large':
      sizeCls = 'l'
      break
    default:
      break
  }

  const classes = classNames(prefixCls, className, {
    [`${prefixCls}-${type}`]: type,
    [`${prefixCls}-${sizeCls}`]: sizeCls,
    [`${prefixCls}-loading`]: loading,
    [`${prefixCls}-danger`]: danger,
    [`${prefixCls}-regular`]: regular,
    [`${prefixCls}-block`]: block,
    [`${prefixCls}-has-prefix`]: !!prefixIcon,
    [`${prefixCls}-has-suffix`]: !!suffixIcon,
  })

  return (
    <button {...omit(others, ['loading'])} type={htmlType || 'button'} className={classes} onClick={handleClick}>
      {!!prefixIcon && <Icon type={prefixIcon} className="ant-btn-prefix-icon" />}
      {children}
      {!!suffixIcon && <Icon type={suffixIcon} className="ant-btn-suffix-icon" />}
    </button>
  )
}
