import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import paths from '../../constants/paths'
import Employee from '../../model/employee'
import PayRoll from '../../model/payRoll'
import Transfer, { TransferType } from '../../model/transfer'
import Warning from '../../model/warning'
import { formatTransferType } from '../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

export type WarningEmployeeIDs = Warning & {
  employeeIDs?: string[]
}

type Props = {
  payRoll: PayRoll
  warnings: WarningEmployeeIDs[]
  employeesMap: Record<string, Employee>
  transfers: List<Transfer>
  expanded: boolean

  setWarningsVisibility: (visible: boolean) => void
  deleteWarning: (id: string) => void

  canSwitchToNETS: boolean
  isSupportUser: boolean
  setSwitchToNETSVisibility: () => void
  setFixNationalIDVisibility: () => void
}

export default function WarningsCard(props: Props): ReactElement | null {
  const getEmployee = (id?: string) => {
    if (!id) {
      return undefined
    }
    return props.employeesMap[id]
  }

  const toggleExpanded = () => {
    props.setWarningsVisibility(!props.expanded)
  }

  if (props.warnings.length === 0) {
    return null
  }
  return (
    <Card className="warnings-card">
      <Row>
        <Col span={6}>
          <Subtitle>{t('pay_roll.single.warnings.title')}</Subtitle>
        </Col>
        <Col span={12}>{tx('pay_roll.single.warnings.total', { total: <strong>{props.warnings.length}</strong> })}</Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <span
            onClick={toggleExpanded}
            className={'pay-roll-toggle' + (props.expanded ? ' pay-roll-toggle-visible' : '')}
          >
            {props.expanded ? t('pay_roll.single.warnings.toggle.hide') : t('pay_roll.single.warnings.toggle.show')}
          </span>
        </Col>
      </Row>

      <div className={'warnings-container' + (props.expanded ? ' visible' : '')}>
        {props.warnings.map((warning) => {
          let link = ''
          let message: string | ReactElement = ''
          let button = null
          switch (warning.warningType) {
            case 'EmployeeEmptyTaxCard':
              link = '/' + paths.EMPLOYEES + '/' + warning.subjectID + '/tax-cards'
              message = t('pay_roll.single.warnings.warning.employee_empty_tax_card', {
                name: getEmployee(warning.subjectID)?.name || t('pay_roll.single.warnings.warning.unknown_employee'),
              })
              break
            case 'EmployeeMissingTaxCard':
              link = '/' + paths.EMPLOYEES + '/' + warning.subjectID + '/tax-cards'
              message = t('pay_roll.single.warnings.warning.employee_missing_tax_card', {
                name: getEmployee(warning.subjectID)?.name || t('pay_roll.single.warnings.warning.unknown_employee'),
              })
              break
            case 'ManualTransfer':
              link = '/' + paths.COMPANIES + '/' + warning.companyID + '/transfers'
              if (props.canSwitchToNETS) {
                button = {
                  onClick: props.setSwitchToNETSVisibility,
                  text: t('pay_roll.single.warnings.warning.manual_transfers.switch_to_nets'),
                }
              }
              message = (
                <>
                  {t('pay_roll.single.warnings.warning.manual_transfers.line_1')}
                  <br />
                  {t('pay_roll.single.warnings.warning.manual_transfers.line_2')}
                  <br />
                  {props.canSwitchToNETS && (
                    <>
                      {t('pay_roll.single.warnings.warning.manual_transfers.switch_to_nets_line')}
                      <br />
                    </>
                  )}
                  {t('pay_roll.single.warnings.warning.manual_transfers.line_3')}{' '}
                  {props.transfers
                    .reduce((transfers: { type: TransferType; manual: number; total: number }[], transfer) => {
                      const idx = transfers.findIndex((t) => t.type === transfer.type)
                      if (idx === -1) {
                        transfers.push({
                          type: transfer.type,
                          total: 1,
                          manual: transfer.paymentMethod === 'Manual' ? 1 : 0,
                        })
                      } else {
                        transfers[idx].total += 1
                        if (transfer.paymentMethod === 'Manual') {
                          transfers[idx].manual += 1
                        }
                      }
                      return transfers
                    }, [])
                    .filter((type) => type.manual > 0)
                    .map((type) => {
                      if (type.manual !== type.total) {
                        return t('pay_roll.single.warnings.warning.manual_transfers.out_of_format', {
                          type: formatTransferType(type.type),
                          manual: formatNumber(type.manual),
                          total: formatNumber(type.total),
                        })
                      }
                      return formatTransferType(type.type)
                    })
                    .sort((a, b) => a.localeCompare(b))
                    .join(', ')}
                </>
              )
              break
            case 'ForcedNETSTransfer':
              message = t('pay_roll.single.warnings.warning.forced_nets_transfer')
              break
            case 'NeedCreditCardIntegration':
              link = '/' + paths.COMPANIES + '/' + warning.companyID + '/invoices'
              message = t('pay_roll.single.warnings.warning.need_credit_card_integration')
              break
            case 'UnapprovedCarAllowances':
              link = '/' + paths.APPROVE_TAB
              message = t('pay_roll.single.warnings.warning.unapproved_car_allowances', {
                names: JSON.parse(warning.parameter)
                  .map((item: string) => {
                    const employee = getEmployee(item)
                    return employee ? employee.name : null
                  })
                  .filter((item: string | null) => !!item)
                  .join(', '),
              })
              break
            case 'UnapprovedOneTimePays':
              link = '/' + paths.APPROVE_TAB
              message = t('pay_roll.single.warnings.warning.unapproved_one_time_pays', {
                names: JSON.parse(warning.parameter)
                  .map((item: string) => {
                    const employee = getEmployee(item)
                    return employee ? employee.name : null
                  })
                  .filter((item: string | null) => !!item)
                  .join(', '),
              })
              break
            case 'UnapprovedReimbursements':
              link = '/' + paths.APPROVE_TAB
              message = t('pay_roll.single.warnings.warning.unapproved_reimbursements', {
                names: JSON.parse(warning.parameter)
                  .map((item: string) => {
                    const employee = getEmployee(item)
                    return employee ? employee.name : null
                  })
                  .filter((item: string | null) => !!item)
                  .join(', '),
              })
              break
            case 'UnapprovedTimeRegistrations':
              link = '/' + paths.APPROVE_TAB
              message = t('pay_roll.single.warnings.warning.unapproved_time_registrations', {
                names: JSON.parse(warning.parameter)
                  .map((item: string) => {
                    const employee = getEmployee(item)
                    return employee ? employee.name : null
                  })
                  .filter((item: string | null) => !!item)
                  .join(', '),
              })
              break
            case 'UnapprovedLeaveRegistrations':
              link = '/' + paths.APPROVE_TAB
              message = t('pay_roll.single.warnings.warning.unapproved_leave_registrations', {
                names: JSON.parse(warning.parameter)
                  .map((item: string) => {
                    const employee = getEmployee(item)
                    return employee ? employee.name : null
                  })
                  .filter((item: string | null) => !!item)
                  .join(', '),
              })
              break
            case 'VoucherMissingAccount':
              link = '/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING
              message = t('pay_roll.single.warnings.warning.voucher_missing_account', {
                accounts: JSON.parse(warning.parameter).join(', '),
              })
              break
            case 'EmployeeHasLeaveDifferences': {
              link = '/' + paths.EMPLOYEES + '/' + warning.subjectID + '/leave'
              message = t('pay_roll.single.warnings.warning.employee_has_leave_differences', {
                name: getEmployee(warning.subjectID)?.name || t('pay_roll.single.warnings.warning.unknown_employee'),
              })
              break
            }
            case 'ExcessVacationTaken': {
              link = '/' + paths.EMPLOYEES + '/' + warning.subjectID + '/leave'
              const data = JSON.parse(warning.parameter)
              message = t(
                data.paidVacation
                  ? 'pay_roll.single.warnings.warning.excess_vacation_taken.paid_vacation'
                  : 'pay_roll.single.warnings.warning.excess_vacation_taken.unpaid_vacation',
                {
                  name: getEmployee(warning.subjectID)?.name || t('pay_roll.single.warnings.warning.unknown_employee'),
                  amount: formatCurrency(data.amount, 2),
                  count: data.days,
                }
              )
              break
            }
            case 'EmployeeHasAssets':
              link = '/' + paths.EMPLOYEES + '/' + warning.subjectID + '/assets'
              message = t('pay_roll.single.warnings.warning.employee_has_assets', {
                name: getEmployee(warning.subjectID)?.name || t('pay_roll.single.warnings.warning.unknown_employee'),
              })
              break
            case 'InsufficientPayslipVouchers': {
              const data = JSON.parse(warning.parameter)
              message = t('pay_roll.single.warnings.warning.insufficient_payslip_vouchers', {
                missing: data.Missing,
              })
              break
            }
            case 'TerminatedEmployeesInPeriod':
              type TerminatedEmployee = {
                Name: string
                PaidOut: number
                Dispensed: number
              }
              if (warning.parameter) {
                message = (
                  <>
                    {t('pay_roll.single.warnings.warning.terminated_employees_in_period')}
                    {JSON.parse(warning.parameter).map((item: TerminatedEmployee, i: number) => {
                      return (
                        <React.Fragment key={i}>
                          <br />
                          {t('pay_roll.single.warnings.warning.terminated_employees_in_period.row', {
                            name: item.Name,
                            count: item.Dispensed,
                            paid_out: formatCurrency(item.PaidOut, 2),
                          })}
                        </React.Fragment>
                      )
                    })}
                  </>
                )
              }
              break
            case 'PromissoryNote':
              type PromissoryNoteEmployee = {
                EmployeeID: string
                PromissoryNote: number
              }
              if (warning.parameter) {
                message = (
                  <>
                    {t('pay_roll.single.warnings.warning.promissory_note')}
                    {JSON.parse(warning.parameter).map((item: PromissoryNoteEmployee, i: number) => {
                      const employee = props.employeesMap[item.EmployeeID]
                      return (
                        <React.Fragment key={i}>
                          <br />
                          {employee.name}: {formatCurrency(item.PromissoryNote, 2)}
                        </React.Fragment>
                      )
                    })}
                  </>
                )
              }
              break
            case 'EmployeesSharingTransferDestination': {
              const data = JSON.parse(warning.parameter)
              const employeeIDs = data['EmployeeIDs']
              if (employeeIDs) {
                message = (
                  <>
                    {t('pay_roll.single.warnings.warning.employees_sharing_transfer_destination')}:{' '}
                    {employeeIDs.map((employeeID: string, i: number) => {
                      const employee = getEmployee(employeeID)
                      if (!employee) {
                        return null
                      }
                      return (
                        <React.Fragment key={i}>
                          {i > 0 ? ', ' : ''}
                          <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/profile'}>{employee.name}</Link>
                        </React.Fragment>
                      )
                    })}
                  </>
                )
              } else {
                link = '/' + paths.EMPLOYEES
                message = t('pay_roll.single.warnings.warning.employees_sharing_transfer_destination.unknown')
              }
              break
            }
            case 'UnreadyEmployeesOnPayroll':
              link = '/' + paths.APPROVE_TAB
              message = t('pay_roll.single.warnings.warning.unready_employees_on_payroll', {
                names: JSON.parse(warning.parameter)
                  .map((item: string) => {
                    const employee = getEmployee(item)
                    return employee ? employee.name : null
                  })
                  .filter((item: string | null) => !!item)
                  .join(', '),
              })
              break
            case 'TINMissing':
            case 'TINNoMatch': {
              const employeeIDs = JSON.parse(warning.parameter) as string[]
              message = (
                <>
                  {tx(
                    [
                      'pay_roll',
                      'single',
                      'warnings',
                      'warning',
                      warning.warningType === 'TINMissing' ? 'tin_missing' : 'tin_no_match',
                    ],
                    {
                      count: employeeIDs.length,
                      names: employeeIDs.map((employeeID, i) => {
                        const employee = getEmployee(employeeID)
                        if (!employee) {
                          return null
                        }
                        return (
                          <React.Fragment key={i}>
                            {i > 0 ? ', ' : ''}
                            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/profile'}>{employee.name}</Link>
                          </React.Fragment>
                        )
                      }),
                    }
                  )}
                </>
              )
              break
            }
            case 'EmployeeInvalidNationalID':
              if (!warning.employeeIDs || warning.employeeIDs.length === 0) {
                break
              }
              message = t('pay_roll.single.warnings.warning.employee_invalid_national_id', {
                count: warning.employeeIDs.length,
                names: warning.employeeIDs
                  .map((employeeID) => {
                    const employee = getEmployee(employeeID)
                    return employee?.name || employee?.email || null
                  })
                  .filter((item: string | null) => !!item)
                  .join(', '),
              })
              if (props.isSupportUser) {
                button = {
                  onClick: props.setFixNationalIDVisibility,
                  text: t('pay_roll.single.warnings.warning.employee_invalid_national_id.fix_national_id'),
                }
              }
              break
            default:
              break
          }
          let alertMessage = link ? <Link to={link}>{message}</Link> : message
          if (button) {
            alertMessage = (
              <Row>
                <Col span={18}>{alertMessage}</Col>
                <Col span={6}>
                  <Button onClick={button.onClick} style={{ padding: '8px 10px' }}>
                    {button.text}
                  </Button>
                </Col>
              </Row>
            )
          }
          return (
            <Alert
              key={warning.id}
              message={alertMessage}
              type={'warning'}
              showIcon
              closable={warning.canAcknowledge}
              onClose={() => {
                props.deleteWarning(warning.id)
              }}
            />
          )
        })}
      </div>
    </Card>
  )
}
