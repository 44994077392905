import React, { ReactElement } from 'react'

import Icon from '../icon'
import { iconToComponent } from '../icon/iconToComponent'
import Button from './button'

type Props = {
  type: keyof typeof iconToComponent
  title?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
  danger?: boolean
  size?: 'default' | 'large' | 'extra-large' | 'extra-extra-large'
}

export default function IconButton(props: Props): ReactElement | null {
  const { type, ...restProps } = props
  return (
    <Button className="ant-btn-icon" {...restProps}>
      <Icon type={type} />
    </Button>
  )
}
