import React, { ReactElement, ReactNode, useState } from 'react'

import { t } from '../../utils/translation-utils'
import Modal from './modal'

type Props = {
  toggle?: ReactNode
  className?: string
  children: ReactNode
}

export default function HelpModal(props: Props): ReactElement {
  const [showModal, setShowModal] = useState(false)
  const toggle = (
    <span
      className={props.className ?? 'help-modal-toggle'}
      onClick={() => setShowModal((prev) => !prev)}
      title={t('help_modal.toggle_text')}
    >
      {props.toggle}
    </span>
  )
  return (
    <>
      {toggle}
      <Modal
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        width={376}
        footer={null}
        className="help-modal-container"
      >
        {props.children}
      </Modal>
    </>
  )
}
