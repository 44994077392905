import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import PDFPreviewTypes from '../../constants/pdf-preview-types'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import PayRoll from '../../model/payRoll'
import { ReportType } from '../../model/report'
import Voucher, { VoucherLine } from '../../model/voucher'
import { CompanyAccountingIntegrationReducer } from '../../reducers/companyAccountingIntegration'
import { UserReducer } from '../../reducers/user'
import { formatAccountingIntegration } from '../../utils/format-utils'
import { formatNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Popover from '../elements/popover'
import Subtitle from '../elements/Subtitle'
import Table from '../elements/table'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import ReportButton, { RegularReportFields } from '../widgets/ReportButton'

const columns = [
  {
    title: t('pay_roll.single.accounting.table.header.account_number'),
    dataIndex: 'accountNumber',
    key: 'accountNumber',
  },
  { title: t('pay_roll.single.accounting.table.header.description'), dataIndex: 'description', key: 'description' },
  { title: t('pay_roll.single.accounting.table.header.debit'), dataIndex: 'debit', key: 'debit' },
  { title: t('pay_roll.single.accounting.table.header.credit'), dataIndex: 'credit', key: 'credit' },
  { title: t('pay_roll.single.accounting.table.header.vat'), dataIndex: 'vat', key: 'vat' },
]

type Props = {
  payRoll: PayRoll
  user: UserReducer
  asynchronousTasks: List<AsynchronousTask>
  company: Company
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  vouchers: List<Voucher>

  addAlert: addAlertSignature
}

export default function AccountingCard(props: Props): ReactElement | null {
  const [expanded, setExpanded] = useState(false)

  const voucher = props.vouchers.first()
  if (!voucher) {
    return null
  }

  const toggleExpanded = () => setExpanded(!expanded)

  const getVouchers = () => {
    return props.vouchers
      .reduce((lines: VoucherLine[], voucher) => {
        if (voucher.lines) {
          return lines.concat(voucher.lines)
        }
        return lines
      }, [])
      .filter((line) => line.amount)
      .map((line, i) => {
        return {
          key: `${line.type}-${i}`,
          accountNumber: line.hasAccount ? line.accountNumber : t('pay_roll.single.accounting.table.unknown_account'),
          description: line.text,
          debit: line.amount > 0 ? formatNumber(line.amount, 2) : '',
          credit: line.amount < 0 ? formatNumber(-line.amount, 2) : '',
          vat: line.vatAmount ? formatNumber(line.vatAmount, 2) : '',
        }
      })
  }

  const isBookingAutomatic = () => {
    // there will usually be one voucher per pay roll, but in case there is not, we manual trumps automatic
    return props.vouchers.reduce((automatic, voucher) => {
      if (!voucher.automated) {
        automatic = false
      }
      return automatic
    }, true)
  }

  const { integrationType, displayName } = props.companyAccountingIntegration
  const automatic = isBookingAutomatic()

  const payRollFields = (report: ReportType): RegularReportFields => ({
    type: 'Regular',
    companyID: props.company.id,
    payRollID: props.payRoll.id,
    from: '0001-01-01',
    to: '0001-01-01',
    report,
  })

  const vouchers = getVouchers()

  return (
    <Card>
      <Row>
        <Col span={6}>
          <Subtitle>{t('pay_roll.single.accounting.header.title')}</Subtitle>
        </Col>
        <Col span={12}>
          {!automatic
            ? t('pay_roll.single.accounting.header.manual')
            : tx('pay_roll.single.accounting.header.automatic', {
                type: <strong>{displayName || formatAccountingIntegration(integrationType || 'None')}</strong>,
              })}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <span onClick={toggleExpanded} className={'pay-roll-toggle' + (expanded ? ' pay-roll-toggle-visible' : '')}>
            {expanded
              ? t('pay_roll.single.accounting.header.toggle.hide')
              : t('pay_roll.single.accounting.header.toggle.show')}
          </span>
        </Col>
      </Row>

      <div className={'pay-roll-card-container' + (expanded ? ' pay-roll-card-container-visible' : '')}>
        <Row className="pay-roll-card-buttons">
          <Col span={24} style={{ textAlign: 'right' }}>
            <Popover
              placement="bottomRight"
              content={
                <div>
                  <ReportButton
                    state={1}
                    asynchronousTasks={props.asynchronousTasks}
                    type={'Excel'}
                    addAlert={props.addAlert}
                    getFields={() => payRollFields('WorkFlowIncomeTaxReport')}
                    icon={<Icon type="document" />}
                    text={t('pay_roll.single.accounting.header.income_tax_report.excel')}
                  />
                  <ReportButton
                    state={1}
                    asynchronousTasks={props.asynchronousTasks}
                    type={'PDF'}
                    addAlert={props.addAlert}
                    getFields={() => payRollFields('WorkFlowIncomeTaxReport')}
                    icon={<Icon type="document" />}
                    text={t('pay_roll.single.accounting.header.income_tax_report.pdf')}
                  />
                </div>
              }
              trigger="click"
              overlayClassName="context-menu-popover"
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
              <Button prefixIcon="document">{t('pay_roll.single.accounting.header.income_tax_report')}</Button>
            </Popover>
            <Popover
              placement="bottomRight"
              content={
                <div>
                  <ReportButton
                    state={1}
                    asynchronousTasks={props.asynchronousTasks}
                    type={'Excel'}
                    addAlert={props.addAlert}
                    getFields={() => payRollFields('PayRollEmployees')}
                    icon={<Icon type="document" />}
                    text={t('pay_roll.single.accounting.header.employees_report.excel')}
                  />
                  <ReportButton
                    state={1}
                    asynchronousTasks={props.asynchronousTasks}
                    type={'PDF'}
                    addAlert={props.addAlert}
                    getFields={() => payRollFields('SalaryReceipt')}
                    icon={<Icon type="document" />}
                    text={t('pay_roll.single.accounting.header.employees_report.pdf')}
                  />
                </div>
              }
              trigger="click"
              overlayClassName="context-menu-popover"
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
              <Button prefixIcon="document">{t('pay_roll.single.accounting.header.employees_report')}</Button>
            </Popover>
            {props.vouchers.size > 0 && (
              <Popover
                placement="bottomRight"
                content={
                  <div>
                    {props.user.userType === 'Admin' || props.user.userType === 'Support' ? (
                      <DumbLink
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault()
                          jsBrowserHistory.push(
                            '/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.ACCOUNTING_VOUCHER + '/' + voucher.id
                          )
                        }}
                      >
                        <Button prefixIcon="document">
                          {t('pay_roll.single.accounting.header.accounting_voucher.pdf')}
                        </Button>
                      </DumbLink>
                    ) : (
                      <Link
                        to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.ACCOUNTING_VOUCHER + '/' + voucher.id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button prefixIcon="document">
                          {t('pay_roll.single.accounting.header.accounting_voucher.pdf')}
                        </Button>
                      </Link>
                    )}
                    <ReportButton
                      state={1}
                      asynchronousTasks={props.asynchronousTasks}
                      type={'Excel'}
                      addAlert={props.addAlert}
                      getFields={() => payRollFields('PayRollAccounting')}
                      isValid={() => true}
                      icon={<Icon type="document" />}
                      text={t('pay_roll.single.accounting.header.accounting_voucher.excel')}
                    />
                    <ReportButton
                      state={1}
                      asynchronousTasks={props.asynchronousTasks}
                      type={'CSV'}
                      addAlert={props.addAlert}
                      getFields={() => payRollFields('PayRollAccounting')}
                      isValid={() => true}
                      icon={<Icon type="document" />}
                      text={t('pay_roll.single.accounting.header.accounting_voucher.csv')}
                    />
                  </div>
                }
                trigger="click"
                overlayClassName="context-menu-popover"
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
              >
                <Button>
                  <Icon type="document" />
                  {t('pay_roll.single.accounting.header.accounting_voucher')}
                </Button>
              </Popover>
            )}
            <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}>
              <Button prefixIcon="spanner">{t('pay_roll.single.accounting.header.settings')}</Button>
            </Link>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={vouchers}
          pagination={vouchers.length > 100 ? { defaultPageSize: 100 } : false}
          className={'pay-roll-table pay-roll-table-small'}
        />
      </div>
    </Card>
  )
}
