import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { TimeRegistrationBulk } from '../../api/time-registrations'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import { TimeRegistrationMethodType } from '../../model/contract'
import Department from '../../model/department'
import LeaveBalance from '../../model/leaveBalance'
import LeaveType, { LeaveTypeName } from '../../model/leaveType'
import SalaryType from '../../model/salaryType'
import { TimeRegistrationMutableFields } from '../../model/timeRegistration'
import { EmployeeReducer } from '../../reducers/employees'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { formatLeaveTypeName } from '../../utils/format-utils'
import { formatDisplayNumber } from '../../utils/number-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { getPage, setPage } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'
import ContextMenu from '../elements/ContextMenu'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import { Col, Row } from '../elements/grid'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Table from '../elements/table'
import { TableChange } from '../elements/table/Table'
import UserImage from '../elements/UserImage'
import LeaveEdit from '../employees-single/leave/LeaveEdit'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company: Company
  companyUser?: CompanyUser
  employees: EmployeeReducer
  departments: List<Department>
  timeRegistrations: TimeRegistrationReducer
  leaveBalances: List<LeaveBalance>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>

  getRemuneration: (id: string) => void
  createTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  approveTimeRegistrations: (ids: string[]) => void
}

export default function LeaveRegistrationTable(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEdit, setShowEdit] = useState<string | boolean>(false)
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })
  type FilteredLeaveTypeName = LeaveTypeName | 'Other'
  type SortOn = 'employee' | `leave${FilteredLeaveTypeName}` | 'hasUnapproved'
  type Sort = {
    sortOn?: SortOn
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const { timeRegistrations } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  const setEditVisibility = (showEdit: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowEdit(showEdit)
  }

  useEffect(() => {
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations.saving) {
      if (!timeRegistrations.error) {
        setEditVisibility(false)
      }
    }
  })

  const approveAll = (employeeID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (window.confirm(t('common.are_you_sure'))) {
        const ids = props.timeRegistrations.timeRegistrations
          .filter((reg) => reg.employeeID === employeeID && reg.class === 'Leave' && !reg.approved)
          .toArray()
          .map((reg) => reg.id)
        props.approveTimeRegistrations(ids)
      }
    }
  }

  type EmployeeRowBalance = {
    used: number
    left: number
    registered: number
    unapproved: number
  }

  type EmployeeRow = {
    key: string
    id: string
    type: TimeRegistrationMethodType
    name: string
    profileImageURL?: string
    canApproveObjects: boolean
    canEditObjects: boolean
    vacationExcessLimit?: number
    registrations: Record<string, EmployeeRowBalance>
  }

  type Column = {
    title: string
    dataIndex: string
    key: string
    className?: string
    width?: number
    sorter?: SortOn
    render?: (employee: EmployeeRow) => ReactElement | ReactElement[] | string | null
  }

  const leaveTypeNames = props.leaveTypes.reduce((hash: Record<string, string>, leaveType) => {
    hash[leaveType.name] = formatLeaveTypeName(leaveType.name)
    hash[leaveType.name + '-p'] = formatLeaveTypeName(leaveType.name, true)
    return hash
  }, {})

  const getLeaveTypes = () => {
    const knownLeaveTypeIds: string[] = []
    props.leaveBalances.forEach((leaveBalance) => {
      if (knownLeaveTypeIds.indexOf(leaveBalance.leaveTypeID) === -1) {
        knownLeaveTypeIds.push(leaveBalance.leaveTypeID)
      }
    })
    const leaveTypes: Partial<Record<FilteredLeaveTypeName, { title: string; leaveTypeIDs: string[] }>> = {}
    props.leaveTypes.forEach((leaveType) => {
      if (leaveType.name === 'DenmarkFlexTime' || leaveType.name === 'DenmarkOvertime') {
        return
      }
      if (knownLeaveTypeIds.indexOf(leaveType.id) !== -1) {
        let name: FilteredLeaveTypeName = leaveType.name
        let title = leaveTypeNames[leaveType.name]
        if (
          leaveType.name === 'DenmarkDayOff' ||
          leaveType.name === 'DenmarkSickDayPaid' ||
          leaveType.name === 'DenmarkRemoteWorkDay'
        ) {
          title = t('leave_registrations.table.other_type')
          name = 'Other'
        }
        if (!leaveTypes[name]) {
          leaveTypes[name] = { title, leaveTypeIDs: [] }
        }
        leaveTypes[name]!.leaveTypeIDs.push(leaveType.id)
      }
    })
    return leaveTypes
  }

  const getColumns = (): Column[] => {
    const columns: Column[] = [
      {
        title: t('leave_registrations.table.header.employee'),
        dataIndex: '',
        key: 'xEmployee',
        width: 250,
        sorter: 'employee',
        render: (employee: EmployeeRow) => {
          return (
            <Headline>
              <UserImage src={employee.profileImageURL} name={employee.name} />
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>{employee.name}</Link>
            </Headline>
          )
        },
      },
    ]
    const leaveTypes = getLeaveTypes()
    const names = Object.keys(leaveTypes) as FilteredLeaveTypeName[]
    names.sort((a, b) => {
      if (a === 'Other') {
        return 1
      }
      if (b === 'Other') {
        return -1
      }
      return leaveTypes[a]!.title.localeCompare(leaveTypes[b]!.title)
    })
    const companyVacationLimit = props.company.vacationExcessLimit || 0
    names.forEach((name) => {
      const leaveType = leaveTypes[name]
      if (!leaveType) {
        return
      }
      columns.push({
        title: leaveType.title,
        dataIndex: '',
        key: 'x' + name,
        width: name.length > 10 ? (name.length > 15 ? 145 : 125) : 115,
        sorter: `leave${name}`,
        render: (employee) => {
          if (name === 'Other') {
            const children: ReactElement[] = []
            leaveType.leaveTypeIDs.forEach((leaveTypeID) => {
              const result = employee.registrations[leaveTypeID]
              if (result) {
                const used = result.used + result.registered
                if (used !== 0) {
                  const leaveType = props.leaveTypes.find((leaveType) => leaveType.id === leaveTypeID)
                  if (leaveType) {
                    let displayName = leaveTypeNames[leaveType.name + (used !== 1 ? '-p' : '')]
                    displayName = displayName[0].toLowerCase() + displayName.substring(1)
                    children.push(
                      <div key={`other-${leaveTypeID}`}>
                        {formatDisplayNumber(used)} {displayName}
                      </div>
                    )
                  }
                }
              }
            })
            if (children.length === 0) {
              return '-'
            }
            return children
          }
          let hasValue = false
          let used = 0
          let left = 0
          leaveType.leaveTypeIDs.forEach((leaveTypeID) => {
            const result = employee.registrations[leaveTypeID]
            if (result) {
              hasValue = true
              used += result.used
              used += result.registered
              left += result.left
            }
          })
          if (hasValue) {
            const children = []
            const showLeft = name !== leaveTypeNames['DenmarkUnpaidDayOff']
            const showBorrowed =
              name === leaveTypeNames['DenmarkVacationPaid'] ||
              name === leaveTypeNames['DenmarkVacationPaidAdditional'] ||
              name === leaveTypeNames['DenmarkVacationNoPay'] ||
              name === leaveTypeNames['DenmarkVacationAccrual'] ||
              name === leaveTypeNames['DenmarkVacationFund'] ||
              name === leaveTypeNames['DenmarkVacationFundWithPension']
            if (showLeft) {
              children.push(
                <span key="left">
                  {t('leave_registrations.table.descriptor.left', { amount: formatDisplayNumber(left) })}
                  <br />
                </span>
              )
            }
            children.push(
              <span key="used">
                {t('leave_registrations.table.descriptor.used', { amount: formatDisplayNumber(used) })}
              </span>
            )
            if (showBorrowed && left < 0) {
              let borrowed = Math.abs(left)
              const vacationLimit = employee.vacationExcessLimit ?? companyVacationLimit
              if (borrowed > vacationLimit) {
                borrowed = vacationLimit
              }
              if (borrowed > 0) {
                children.push(
                  <span key="borrowed">
                    <br />
                    {t('leave_registrations.table.descriptor.borrowed', { amount: formatDisplayNumber(borrowed) })}
                  </span>
                )
              }
            }
            return children
          }
          return '-'
        },
      })
    })
    columns.push({
      title: t('leave_registrations.table.header.has_unapproved'),
      dataIndex: '',
      key: 'xHasUnapproved',
      width: 120,
      className: 'ant-table-col-state',
      sorter: 'hasUnapproved',
      render: (employee) => {
        if (!employee.canApproveObjects) {
          return null
        }
        let hasValue = false
        const unapproved: string[] = []
        names.forEach((name) => {
          leaveTypes[name]!.leaveTypeIDs.forEach((leaveTypeID) => {
            const result = employee.registrations[leaveTypeID]
            if (result) {
              if (result.used !== 0 || result.left !== 0) {
                hasValue = true
              }
              if (result.unapproved > 0) {
                unapproved.push(
                  t('leave_registrations.table.unapproved_format', {
                    days: t('unit.day_count', { count: result.unapproved }),
                    name: name === 'Other' ? t('leave_registrations.table.other_type') : leaveTypeNames[name],
                  })
                )
              }
            }
          })
        })
        if (!hasValue) {
          return ''
        }
        if (props.timeRegistrations.saving) {
          return <LoadingOverlay />
        }
        if (unapproved.length > 0) {
          return (
            <div>
              <DumbLink onClick={approveAll(employee.id)}>{t('leave_registrations.table.approve_leave')}</DumbLink>
              <br />
              <small>({unapproved.join(', ')})</small>
            </div>
          )
        }
        return t('leave_registrations.table.all_approved')
      },
    })
    columns.push({
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (employee) => {
        if (!employee.canEditObjects) {
          return null
        }
        return (
          <ContextMenu placeholder={<Icon type="paperWithPencil" />}>
            <DumbLink
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                setEditVisibility(employee.id)
              }}
            >
              <Icon type="user" /> {t('leave_registrations.table.actions.register')}
            </DumbLink>
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/leave'}>
              <Icon type="paperWithPencil" /> {t('leave_registrations.table.actions.edit')}
            </Link>
          </ContextMenu>
        )
      },
    })
    return columns
  }

  const getEmployees = (): EmployeeRow[] => {
    const leaveBalances = props.leaveBalances.reduce((hash: Record<string, LeaveBalance[]>, leaveBalance) => {
      if (hash[leaveBalance.employeeID]) {
        hash[leaveBalance.employeeID].push(leaveBalance)
        return hash
      }
      hash[leaveBalance.employeeID] = [leaveBalance]
      return hash
    }, {})
    const leaveTypesFiltered = props.leaveTypes
      .filter((leaveType) => leaveType.name !== 'DenmarkFlexTime' && leaveType.name !== 'DenmarkOvertime')
      .reduce((hash: Record<string, LeaveType>, leaveType) => {
        hash[leaveType.id] = leaveType
        return hash
      }, {})
    return props.employees.employees
      .filter(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.affiliationType !== 'Freelancer' &&
          !!employee.activeContract &&
          filterEmployee(employee, filter)
      )
      .map((employee) => {
        const result: EmployeeRow = {
          key: employee.id,
          id: employee.id,
          type: employee.activeContract?.timeRegistrationMethodType || 'Coarse',
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          canApproveObjects: hasDepartmentPermission(props.companyUser, employee.departmentID, 'ApproveObjects'),
          canEditObjects: hasDepartmentPermission(props.companyUser, employee.departmentID, 'EditObjects'),
          vacationExcessLimit: employee.vacationExcessLimit,
          registrations: {},
        }
        const timeRegistrations = props.timeRegistrations.timeRegistrations.filter(
          (reg) => reg.employeeID === employee.id && reg.class === 'Leave'
        )

        ;(leaveBalances[employee.id] ?? [])
          // so we only get the leave balances of the types we are interested in
          .filter((leaveBalance) => !!leaveTypesFiltered[leaveBalance.leaveTypeID])
          .forEach((leaveBalance) => {
            let unapproved = 0
            timeRegistrations.forEach((timeRegistration) => {
              if (timeRegistration.leaveTypeID !== leaveBalance.leaveTypeID) {
                return
              }
              if (timeRegistration.settled) {
                return
              }
              if (!timeRegistration.approved) {
                unapproved += timeRegistration.days || 0
              }
            })
            result.registrations[leaveBalance.leaveTypeID] = {
              used: leaveBalance.used,
              left: leaveBalance.left,
              registered: leaveBalance.registered,
              unapproved,
            }
          })
        return result
      })
      .toArray()
      .sort((a, b) => {
        let i = a
        let j = b
        if (sort.sortOrder === 'descend') {
          j = a
          i = b
        }
        switch (sort.sortOn) {
          case 'employee':
            return i.name.localeCompare(j.name)
          case 'hasUnapproved': {
            const names = Object.keys(getLeaveTypes())
            const balance =
              names.reduce((count, name) => count + (j.registrations[name]?.unapproved ?? 0), 0) -
              names.reduce((count, name) => count + (i.registrations[name]?.unapproved ?? 0), 0)
            if (balance === 0) {
              return a.name.localeCompare(b.name)
            }
            return balance
          }
          default: {
            if (sort.sortOn?.startsWith('leave')) {
              const name = sort.sortOn.replace(/^leave/, '') as FilteredLeaveTypeName
              const leaveTypeIDs = getLeaveTypes()[name]!.leaveTypeIDs
              let fallBack = -10000
              if (sort.sortOrder === 'descend') {
                fallBack = 10000
              }
              const rowValue = (row?: EmployeeRowBalance): number => {
                if (!row) {
                  return fallBack
                }
                if (name === 'Other') {
                  if (row.used === 0 && row.registered === 0) {
                    return fallBack
                  }
                  return row.used + row.registered
                }
                return row.left
              }
              const jRegistrations = leaveTypeIDs.reduce(
                (value, typeID) => value + rowValue(j.registrations[typeID]),
                0
              )
              const iRegistrations = leaveTypeIDs.reduce(
                (value, typeID) => value + rowValue(i.registrations[typeID]),
                0
              )
              const balance = jRegistrations - iRegistrations
              if (balance !== 0) {
                return balance
              }
            }
            return a.name.localeCompare(b.name)
          }
        }
      })
  }

  const tableChange = ({ sorter }: TableChange<EmployeeRow>) => {
    if (!sorter.column || !sorter.column.sorter) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'hasUnapproved':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
      default:
        if (sorter.column.sorter.startsWith('leave')) {
          setSort({ sortOn: sorter.column.sorter as `leave${FilteredLeaveTypeName}`, sortOrder: sorter.order })
        }
        break
    }
  }

  const employee = props.employees.employees.find((employee) => employee.id === showEdit)
  return (
    <div>
      <Row>
        <Col span={24}>
          <EmployeeFilter
            departments={props.departments}
            companyUser={props.companyUser}
            onFilterChange={(filter) => setFilter(filter)}
          />
        </Col>
      </Row>
      <Table
        columns={getColumns()}
        dataSource={getEmployees()}
        onChange={tableChange}
        pagination={{
          defaultCurrent: getPage(),
          defaultPageSize: 100,
          onChange: setPage,
        }}
        onRowClick={(employee: EmployeeRow) => {
          setEditVisibility(employee.id)
        }}
      />

      <Modal
        key={modalKey}
        visible={!!showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <LeaveEdit
          visible={!!showEdit}
          company={props.company}
          employee={employee}
          employees={props.employees}
          canApproveObjects={hasDepartmentPermission(
            props.companyUser,
            employee && employee.departmentID,
            'ApproveObjects'
          )}
          timeRegistrations={props.timeRegistrations}
          leaveTypes={props.leaveTypes}
          remunerationType={employee && employee.activeContract && employee.activeContract.remunerationType}
          getRemuneration={props.getRemuneration}
          createTimeRegistration={props.createTimeRegistration}
          createTimeRegistrationBulk={props.createTimeRegistrationBulk}
          updateTimeRegistration={props.updateTimeRegistration}
        />
      </Modal>
    </div>
  )
}
