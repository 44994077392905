import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CompanySetting from '../../model/companySetting'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import HelpModal from '../elements/HelpModal'
import SwitchWrapper from '../form-elements/SwitchWrapper'

type Fields = {
  accountingManualPaymentAlwaysAsset: boolean
  accountingVacationLiability: boolean
  enableOTPAccountingText: boolean
  addConsolidationReportToVoucher: boolean
  disableBankAccounting: boolean
  allowDebitCompanyAccountingAccount: boolean
  skipUnmappedAccountingAccounts: boolean
}

export type AccountingSettingsResult = {
  accountingManualPaymentAlwaysAsset: boolean
  accountingVacationLiability: boolean
  enableSettings: CompanySetting[]
  disableSettings: CompanySetting[]
}

type Props = {
  company: Company
}

function AccountingSettings(props: Props & FormComponentProps<Fields, AccountingSettingsResult>): ReactElement | null {
  const { decorateField } = props
  return (
    <div>
      <Row>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'accountingManualPaymentAlwaysAsset'} decorateField={decorateField}>
            {t('accounting_tab.settings.form.accounting_manual_payment_always_asset')}
            <HelpModal>
              <p>{t('accounting_tab.settings.form.accounting_manual_payment_always_asset.help.line_1')}</p>
              <p>{t('accounting_tab.settings.form.accounting_manual_payment_always_asset.help.line_2')}</p>
            </HelpModal>
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'accountingVacationLiability'} decorateField={decorateField}>
            {t('accounting_tab.settings.form.accounting_vacation_liability')}
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'addConsolidationReportToVoucher'} decorateField={decorateField}>
            {t('accounting_tab.settings.form.add_consolidation_report_to_voucher')}
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'disableBankAccounting'} decorateField={decorateField}>
            {t('accounting_tab.settings.form.disable_bank_accounting')}
            <HelpModal>
              <p>{t('accounting_tab.settings.form.disable_bank_accounting.help.line_1')}</p>
              <p>
                {tx('accounting_tab.settings.form.disable_bank_accounting.help.line_2', {
                  none: <em>{t('accounting_tab.settings.form.disable_bank_accounting.help.line_2.none')}</em>,
                  all: <em>{t('accounting_tab.settings.form.disable_bank_accounting.help.line_2.all')}</em>,
                })}
              </p>
            </HelpModal>
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'enableOTPAccountingText'} decorateField={decorateField}>
            {t('accounting_tab.settings.form.enable_otp_accounting_text')}
            <HelpModal>
              <p>{t('accounting_tab.settings.form.enable_otp_accounting_text.help.line_1')}</p>
              <p>{t('accounting_tab.settings.form.enable_otp_accounting_text.help.line_2')}</p>
              <p>{t('accounting_tab.settings.form.enable_otp_accounting_text.help.line_3')}</p>
            </HelpModal>
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'allowDebitCompanyAccountingAccount'} decorateField={decorateField}>
            {t('accounting_tab.settings.form.allow_debit_company_accounting_account')}
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'skipUnmappedAccountingAccounts'} decorateField={decorateField}>
            {t('accounting_tab.settings.form.skip_unmapped_accounting_accounts')}
            <HelpModal>
              <p>{t('accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_1')}</p>
              <p>{t('accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_2')}</p>
              <p>{t('accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_3')}</p>
            </HelpModal>
          </SwitchWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" className="gtm-accounting-settings-save">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, AccountingSettingsResult>({
  mapPropsToFields: (props) => ({
    accountingManualPaymentAlwaysAsset: props.company.accountingManualPaymentAlwaysAsset,
    accountingVacationLiability: props.company.accountingVacationLiability,
    enableOTPAccountingText: props.company.settingsEnabled.some(
      (setting) => setting === 'OneTimePayAccountingTextEnabled'
    ),
    addConsolidationReportToVoucher: props.company.settingsEnabled.some(
      (setting) => setting === 'AddConsolidationReportToVoucher'
    ),
    disableBankAccounting: props.company.settingsEnabled.some((setting) => setting === 'DisableBankAccounting'),
    allowDebitCompanyAccountingAccount: props.company.settingsEnabled.some(
      (setting) => setting === 'AllowDebitCompanyAccountingAccount'
    ),
    skipUnmappedAccountingAccounts: props.company.settingsEnabled.some(
      (setting) => setting === 'SkipUnmappedAccountingAccounts'
    ),
  }),
  onSubmit: (values) => {
    const enableSettings: CompanySetting[] = []
    const disableSettings: CompanySetting[] = []
    if (values.enableOTPAccountingText) {
      enableSettings.push('OneTimePayAccountingTextEnabled')
    } else {
      disableSettings.push('OneTimePayAccountingTextEnabled')
    }
    if (values.addConsolidationReportToVoucher) {
      enableSettings.push('AddConsolidationReportToVoucher')
    } else {
      disableSettings.push('AddConsolidationReportToVoucher')
    }
    if (values.disableBankAccounting) {
      enableSettings.push('DisableBankAccounting')
    } else {
      disableSettings.push('DisableBankAccounting')
    }
    if (values.allowDebitCompanyAccountingAccount) {
      enableSettings.push('AllowDebitCompanyAccountingAccount')
    } else {
      disableSettings.push('AllowDebitCompanyAccountingAccount')
    }
    if (values.skipUnmappedAccountingAccounts) {
      enableSettings.push('SkipUnmappedAccountingAccounts')
    } else {
      disableSettings.push('SkipUnmappedAccountingAccounts')
    }
    return {
      accountingManualPaymentAlwaysAsset: values.accountingManualPaymentAlwaysAsset,
      accountingVacationLiability: values.accountingVacationLiability,
      enableSettings,
      disableSettings,
    }
  },
})(AccountingSettings)
