import RcSelect from 'rc-select'
import { valueType } from 'rc-select/es/PropTypes'
import React, { CSSProperties, PropsWithChildren, ReactElement, ReactNode } from 'react'

import { getTranslationAntdStrings } from '../../../utils/language-utils'
import classNames from '../../antd/_util/classNames'
import Icon from '../icon'

import './style/css'

type Props = {
  prefixCls?: string
  tabIndex?: number
  className?: string
  dropdownClassName?: string
  value?: valueType
  defaultValue?: valueType
  mode?: 'combobox' | 'tags'
  size?: 'medium' | 'large' | 'extra-large'
  allowClear?: boolean
  notFoundContent?: ReactNode
  showSearch?: boolean
  optionLabelProp?: 'title' | 'value' | 'children'
  transitionName?: string
  choiceTransitionName?: string
  dropdownMatchSelectWidth?: boolean
  filterOption?: (input: string, element: ReactElement) => boolean
  onSelect?: (value: valueType) => void
  onChange?: (value: valueType) => void
  placeholder?: string
  style?: CSSProperties
  disabled?: boolean
}

export default function Select(props: PropsWithChildren<Props>): ReactElement | null {
  const {
    prefixCls = 'ant-select',
    className = '',
    mode,
    size = 'large',
    showSearch = false,
    transitionName = 'slide-up',
    choiceTransitionName = 'zoom',
    ...restProps
  } = props

  const extraProps = {
    showSearch,
    transitionName,
    choiceTransitionName,
  }

  const cls = classNames(
    {
      [`${prefixCls}-l`]: size === 'large',
      [`${prefixCls}-xl`]: size === 'extra-large',
    },
    className
  )

  const locale = getTranslationAntdStrings()
  let { notFoundContent = locale.notFoundContent, optionLabelProp } = props
  const isCombobox = mode === 'combobox'
  if (isCombobox) {
    notFoundContent = null
    optionLabelProp = optionLabelProp || 'value'
  }

  const modeConfig = {
    multiple: false,
    tags: mode === 'tags',
    combobox: isCombobox || undefined,
  }

  const handleOnEither = (v: valueType, f?: (v: valueType) => void) => {
    if (f) {
      // sometimes, rc-select will print the value starting with .$ for no reason
      if (typeof v === 'string' && v.startsWith('.$')) {
        v = v.substring(2)
      }
      f(v)
    }
  }

  const handleOnSelect = (v: valueType) => handleOnEither(v, props.onSelect)
  const handleOnChange = (v: valueType) => handleOnEither(v, props.onChange)

  return (
    <RcSelect
      {...restProps}
      {...extraProps}
      {...modeConfig}
      clearIcon={<Icon type="xSignCircle" />}
      onSelect={handleOnSelect}
      onChange={handleOnChange}
      inputIcon={<Icon type="chevronDown" />}
      children={React.Children.map(props.children, (child) => {
        if (child === false) {
          return <></>
        }
        return child
      })}
      prefixCls={prefixCls}
      className={cls}
      optionLabelProp={optionLabelProp || 'children'}
      notFoundContent={notFoundContent}
    />
  )
}
